import React, { useEffect, useState } from "react";
import {
  Container,
  makeStyles,
  Backdrop,
  CircularProgress,
  Box,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Grid,
  Paper,
  FormControl,
  Select,
  MenuItem,
  Link,
  Tooltip,
  Button,
  TextField,
} from "@material-ui/core";

import ProfileUpdateInfo from "../../components/ProfileUpdateInfo/ProfileUpdateInfo";
import "./compliance.scss";
import ApiConfig from "../../../src/config/ApiConfig";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import UploadFile from "./UploadFile";
import { documentDetails, deleteDocument } from "../../store/action";
import Notify from "../../components/Notify/Notify";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import InfoIcon from "@material-ui/icons/Info";
import PublishIcon from "@material-ui/icons/Publish";
import { alpha } from "@material-ui/core/styles/colorManipulator";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import InsertPhotoOutlinedIcon from "@material-ui/icons/InsertPhotoOutlined";
import DeleteIcon from "@material-ui/icons/Delete";
import MessageIcon from "@material-ui/icons/Message";
import moment from "moment";
import CreateDatePickerEvent from "./CreateDatePickerEvent";
import AlertDialog from "../../components/Alert/AlertDialog";
import { height, width } from "@material-ui/system";

const useStyles = makeStyles((theme) => ({
  progressbar: {
    backgroundColor: "#e6e6e6",
    "& .MuiLinearProgress-barColorPrimary": {
      backgroundColor: "#21d5ac",
    },
  },
  btnSecondary: {
    background: "#f78b46",
    width: 140,
    height: 36,
    color: "#fff",
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none",
      background: "#d76f2d",
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  btn1: {
    width: "152px",
    position: "relative",
    height: "43px",
    borderRadius: "4px",
    fontSize: "15px",
    textTransform: "uppercase",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "0 6px",
  },
  root: {
    width: "100%",
    overflowX: "auto",
    padding: 24,
  },
  root1: {
    padding: 24,
    margin: "8px 0",
  },
  desc: {
    fontSize: 16,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  heading: {
    color: "#626161",
  },
  gridItem: {
    borderBottom: "1px solid #ccc",
  },
  btnContainer: {
    "& > *": {
      marginLeft: theme.spacing(2),
      "& svg": {
        width: 20,
        height: "auto",
      },
    },
  },
  accDetailBackColor: {
    backgroundColor: "#eaecfb",
    margin: "0",
    padding: "0 4px",
  },
  mainTitle: {
    fontSize: 18,
    color: "#21D5AC",
    fontWeight: "500",
  },
  boxContainer: {
    borderBottom: "1px solid #ccc",
    "&:last-child": {
      borderBottom: "none",
    },
  },
  selectCon: {
    padding: "16px",
    backgroundColor: "#fff",
    boxShadow: "0 4px 8px rgba(0,0,0,0.10)",
    borderRadius: "4px",
    width: "100%",
    position: "relative", // Ensure the parent element is relative for the pseudo-element to position correctly
    "&:before": {
      content: '""',
      display: "block",
      width: "4px",
      height: "100%",
      backgroundColor: "#a9adcc",
      top: 0,
      left: 0,
      position: "absolute",
    },
  },

  fileCon: {
    marginLeft: "auto",
    minWidth: 140,
    textAlign: "right",
    marginRight: "20px",
    ordeflow: "hidden",
    flex: 1,
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
  },
  statusSelect: {
    "& .MuiSelect-select": {
      padding: "10px 32px 10px 10px",
    },
  },
  statusContainer: {
    width: "100%",
    maxWidth: "100%",
    flex: "0 0 100%",
    padding: "6px !important",
    background: "#eaebed",
    marginBottom: 24,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  statusLabel: {
    marginRight: 16,
    fontWeight: "500",
    color: "#184a7b",
  },
  formControl: {
    display: "flex",
    gap: "10px",
    color: "#18264d",
    flexDirection: "row",
  },
  formControl1: {
    width: 182,
    display: "flex",
    border: "none",
    background: "#184a7b",
    color: "#fff",
    padding: "4px 8px",
    paddingLeft: 12,
    borderRadius: 6,
    "& .MuiInputBase-root": {
      color: "#fff",
      "&:before": {
        border: "none !important",
      },
    },
    "& svg": {
      fill: "#fff",
    },
  },
  cardBox: {
    background: "#cae0f7",
    padding: "12px 20px",
    marginRight: 12,
    minWidth: 220,
    marginBottom: 12,
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    flexGrow: 1,
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(),
      width: "auto",
    },
  },
  searchIcon: {
    width: theme.spacing(6),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
    width: "100%",
  },
  inputInput: {
    paddingTop: theme.spacing(),
    paddingRight: theme.spacing(),
    paddingBottom: theme.spacing(),
    paddingLeft: theme.spacing(1),
    transition: theme.transitions.create("width"),
    width: "100%",
    borderBottom: "1px solid #ccc",
    [theme.breakpoints.up("md")]: {
      width: 200,
    },
  },
  uploadFile: {
    position: "absolute",
    left: "0",
    top: "0",
    opacity: "0",
    zIndex: "9",
    width: "100%",
    height: "100%",
  },
  statusButton: {
    display: "flex",
    alignItems: "center",
    background: " #184a7b",
    borderRadius: 4,

    overflow: "hidden",
    marginBottom: 16,
    "& > .btn": {
      margin: theme.spacing(0),
      width: "auto",
      cursor: "pointer",
      background: "transparent",
      color: "#8dbef0",
      height: "47px",
      boxShadow: "none",
      borderRadius: 0,
      "&.active": {
        background: "#ff8b46",
        color: "#fff",
      },
      "&:hover": {
        background: "#f69d68",
        color: "#fff",
      },
    },
  },
}));

const ComplianceList = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const getToken = localStorage.getItem("token")
    ? localStorage.getItem("token").replace(/['"]+/g, "")
    : "";
  const [selectedFile, setSelectedFile] = useState([]);
  const [uploadPercentage, setUploadPercentage] = useState(0);

  const [expanded, setExpanded] = useState(false);
  const [key2, setKey] = useState("");
  const { documentDetail, documentDetailError, loading } = useSelector(
    (state) => state.addCompliance
  );

  const [key1, aetKey1] = useState("");
  const [addDocMsg, setAddDocMsg] = useState("");
  const [fileSizeMsg, setFileSize] = useState("");
  const [notifyMsg, setNotifyMsg] = useState(false);
  const [loader, setLoader] = useState(false);
  const staffDetail = JSON.parse(localStorage.getItem("staffDetail"));
  var signeeInfo = JSON.parse(window.localStorage.getItem("signeeInfo"));
  const user_id = signeeInfo.id;
  const baseUrl = ApiConfig.API_URL + "uploads/signee_docs/";
  const [expireDate, setExpireDate] = useState(null);
  const [openRemarkDocument, setOpenRemarkDocument] = useState(false);
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [deleteDocOpen, setDeleteDocOpen] = useState(false);
  const [docId, setDocsId] = useState(false);
  const [complainceStatus, setComplainceStatus] = useState({
    signee_id: user_id,
    organization_id: "",
    key: "",
    document_status: "",
  });
  const [remarksDocument, setRemarksDocument] = useState({
    document_subkey: "",
    document_id: "",
    remark: "",
  });

  const [exDateDocument, setDatePicker] = useState({
    document_subkey: "",
    document_id: "",
    expire_date: "",
  });

  const handleClick = (e, keyData) => {
    setKey(keyData);
    setSelectedFile(e.target.files);
    setFileSize("");
  };

  const handleCloseDatePicker = () => {
    setOpenDatePicker(false);
  };

  useEffect(() => {
    if (selectedFile && selectedFile.length > 0) {
      let totalLength = null;
      if (selectedFile.length <= 5) {
        for (const key of Object.keys(selectedFile)) {
          const fsize = selectedFile.item(key).size;
          const file2 = Math.round(fsize / 1024);
          totalLength += file2;
        }
        if (totalLength <= 20480) {
          onSubmit();
        } else {
          setFileSize("File size should be less than 20MB");
        }
      } else {
        setFileSize("Maximum 5 documents allowed");
      }
    }
  }, [selectedFile]);

  const handleComplianceStatus = (event, org_id) => {
    setComplainceStatus({
      ...complainceStatus,
      key: event.target.name,
      document_status: event.target.value,
      organization_id: org_id,
    });
  };

  const handleClickOpenDatePicker = (
    document_subkey,
    document_id,
    expire_date
  ) => {
    setDatePicker({
      document_subkey: document_subkey,
      document_id: document_id,
      expire_date,
    });
    setOpenDatePicker(true);
  };

  const deleteDocClose = () => {
    setDeleteDocOpen(false);
  };

  const deleteDocResponse = (id) => {
    dispatch(deleteDocument(id));
  };

  const deleteDocumentData = (id) => {
    setDeleteDocOpen(true);
    setDocsId(id);
  };

  const handleClickOpenRemarkDocument = (
    document_subkey,
    document_id,
    remark
  ) => {
    setRemarksDocument({
      document_subkey: document_subkey,
      document_id: document_id,
      remark,
    });
    setOpenRemarkDocument(true);
  };

  const onSubmit = (e) => {
    let formData = new FormData();

    for (const key of Object.keys(selectedFile)) {
      formData.append("files[]", selectedFile[key]);
      formData.append("key", key2);
    }
    setAddDocMsg("");

    axios
      .post(ApiConfig.API_URL + "api/signee/upload-document", formData, {
        // axios.post('http://backendbooking.kanhasoftdev.com/public/api/signee/upload-document', formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: getToken ? `Bearer ${getToken}` : "",
        },
        onUploadProgress: (progressEvent) => {
          setLoader(true);
          // setUploadPercentage(
          //     parseInt(
          //         Math.round((progressEvent.loaded * 100) / progressEvent.total)
          //     )
          // );
        },
      })
      .then(function (response) {
        const dataItem = response.data;
        if (dataItem && dataItem.status === true) {
          dispatch(documentDetails(""));
          setLoader(false);
          setNotifyMsg(true);
          setAddDocMsg("Document Uploaded Successfully");
        }
      })
      .catch(function (error) {
        setLoader(false);
        setAddDocMsg(error.message);
      });
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const getDocUploadedStatus = (docs) => {
    const tempDocs = Object.entries(docs);
    if (
      !tempDocs?.find(
        (obj) => obj?.[1]?.docs?.filter((d) => d.file_name)?.length > 0
      )
    ) {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          style={{ marginRight: "18px" }}
        >
          <circle cx="12" cy="12" r="8" fill="red" />
        </svg>
      );
    } else {
      for (const [, sectionData] of tempDocs) {
        if (!sectionData.docs.length) {
          return (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              style={{ marginRight: "18px" }}
            >
              <circle cx="12" cy="12" r="8" fill="yellow" />
            </svg>
          );
        }
        for (const doc of sectionData.docs) {
          if (
            doc.document_status !== "SUCCESS" &&
            doc.document_status !== "REJECTED" &&
            doc.is_checked !== 0
          ) {
            return (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                style={{ marginRight: "18px" }}
              >
                <circle cx="12" cy="12" r="8" fill="yellow" />
              </svg>
            );
          }
        }
      }
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 24 24"
          style={{ marginRight: "18px" }}
        >
          <rect width="22" height="22" fill="#22d822" rx="4" />
          <path
            fill="white"
            d="M9 16.17l-3.5-3.5a1 1 0 0 1 1.41-1.41L9 13.35l6.59-6.59a1 1 0 0 1 1.41 1.41z"
          />
        </svg>
      );
    }
  };

  useEffect(() => {
    dispatch(documentDetails(""));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {loading || loader ? (
        <Backdrop className={classes.backdrop} open={loading || loader}>
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        ""
      )}
      {notifyMsg && addDocMsg && <Notify data={addDocMsg} status="success" />}

      {fileSizeMsg && <Notify data={fileSizeMsg} status="error" />}
      <ProfileUpdateInfo />

      <section className="pt-16 pb-32">
        <Container maxWidth="lg">
          <h1 className="mt-16">Compliance</h1>
          <Box onSubmit={(e) => onSubmit(e)}>
            {documentDetail?.data &&
              Object.entries(documentDetail?.data).map((list, index) => {
                return (
                  <>
                    <Accordion
                      key={index}
                      expanded={expanded === `panel${index + 1}`}
                      onChange={handleChange(`panel${index + 1}`)}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                      >
                        <Typography variant="h5">
                          {getDocUploadedStatus(list[1]?.data || {})}
                          {list[1].title}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails className={classes?.accDetailBackColor}>
                        <Grid container style={{}} spacing={0}>
                          {list[1].data &&
                            list[1].data &&
                            Object.entries(list[1].data).map(
                              (lists, indexs) => {
                                return (
                                  <Grid item xs={12}>
                                    <Paper
                                      className={`${classes.root1}`}
                                      key={indexs}
                                    >
                                      <Grid container spacing={0}>
                                        <Grid
                                          item
                                          xs={12}
                                          style={{
                                            display: "flex",
                                            flexWrap: "wrap",
                                            position: "relative",
                                            gap: "15px",
                                            justifyContent: "space-between",
                                          }}
                                        >
                                          <div
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            <InfoIcon
                                              style={{
                                                marginRight: 12,
                                                opacity: "0.6",
                                              }}
                                            />

                                            <Typography
                                              className={classes.mainTitle}
                                            >
                                              {lists[1].subTitle}
                                            </Typography>
                                          </div>
                                          {/* {(staffDetail === "Compliance" ||
                                            staffDetail === "Admin" ||
                                            loginDetail.role ===
                                              "ORGANIZATION") && ( */}
                                          <>
                                            <div className={classes.fileCon}>
                                              <button
                                                title="Upload Files"
                                                className="btn1"
                                                style={{
                                                  width: "152px",
                                                  position: " relative",
                                                  height: "43px",
                                                  borderRadius: "4px",
                                                  fontSize: " 15px",
                                                  textTransform: "uppercase",
                                                  display: "flex",
                                                  alignItems: "center",
                                                  justifyContent: "center",
                                                  margin: " 0 6px",
                                                  backgroundColor: "#21d5ac",
                                                  color: "#fff",
                                                  border: "none",
                                                  cursor: "pointer",
                                                }}
                                              >
                                                <PublishIcon />
                                                <input
                                                  key={key1}
                                                  accept="image/*,.pdf"
                                                  type="file"
                                                  multiple
                                                  name="files"
                                                  onChange={(e) =>
                                                    handleClick(
                                                      e,
                                                      lists[1].subKey
                                                    )
                                                  }
                                                  className={classes.uploadFile}
                                                />
                                                <span>Upload Files</span>
                                              </button>
                                            </div>
                                          </>
                                          {/* )} */}
                                        </Grid>
                                        {lists[1]?.docs.length !== 0 && (
                                          <Grid
                                            style={{
                                              display: "flex",
                                              borderTop: "2px solid #daddf0",
                                              backgroundColor: "#f3f4f9",
                                              padding: "16px 24px",
                                              margin: "10px 0px",
                                              width: "100%",
                                            }}
                                          >
                                            <div className={classes.selectCon}>
                                              {lists[1]?.docs.filter(
                                                (d) => d.file_name
                                              )?.length > 0 && (
                                                <FormControl
                                                  variant="outlined"
                                                  className={
                                                    classes.formControl
                                                  }
                                                  fullWidth
                                                >
                                                  {" "}
                                                  <span>
                                                    Document Status :{" "}
                                                  </span>
                                                  <span>
                                                    {lists[1].docs[0]
                                                      ?.document_status ||
                                                      complainceStatus?.document_status ||
                                                      "PENDING"}
                                                  </span>
                                                </FormControl>
                                              )}
                                            </div>
                                          </Grid>
                                        )}
                                        <div
                                          style={{
                                            display: "flex",
                                            gap: "20px",
                                            flexWrap: "wrap",
                                            width: "100%",
                                          }}
                                        >
                                          {lists[1].docs &&
                                            lists[1].docs?.map(
                                              (items, index1) => (
                                                <Grid item xs={12}>
                                                  {items?.file_name ? (
                                                    <div
                                                      className="compliance-container mb-3"
                                                      style={{
                                                        display: "flex",
                                                        flexWrap: "wrap",
                                                        alignItems: "center",
                                                      }}
                                                    >
                                                      <Grid
                                                        container
                                                        spacing={3}
                                                        className="pdf-container"
                                                      >
                                                        <Grid item md={6}>
                                                          <div
                                                            style={{
                                                              display: "flex",
                                                            }}
                                                          >
                                                            <div
                                                              className="image-icon"
                                                              style={{
                                                                marginRight: 12,
                                                                opacity: "0.6",
                                                              }}
                                                            >
                                                              {items?.file_name
                                                                .split(".")
                                                                .pop() ===
                                                              "pdf" ? (
                                                                <PictureAsPdfIcon />
                                                              ) : (
                                                                <InsertPhotoOutlinedIcon />
                                                              )}
                                                            </div>

                                                            <Link
                                                              style={{
                                                                minWidth:
                                                                  "300px",
                                                                maxWidth:
                                                                  "300px",
                                                                whiteSpace:
                                                                  "nowrap",
                                                                overflow:
                                                                  "hidden",
                                                                textOverflow:
                                                                  "ellipsis",
                                                              }}
                                                              to={{
                                                                pathname: `${baseUrl}${items?.file_name}`,
                                                              }}
                                                              target="_blank"
                                                            >
                                                              {items?.file_name}
                                                            </Link>
                                                          </div>
                                                        </Grid>

                                                        <Grid item md={6}>
                                                          <span
                                                            style={{
                                                              paddingRight:
                                                                "5px",
                                                            }}
                                                          >
                                                            Expire Dateasx:{" "}
                                                            {items?.expire_date ===
                                                            null
                                                              ? "N/A"
                                                              : ""}
                                                          </span>
                                                          <span>
                                                            <TextField
                                                              id="expire_date"
                                                              value={
                                                                items?.expire_date ===
                                                                null
                                                                  ? ""
                                                                  : moment(
                                                                      items?.expire_date
                                                                    ).format(
                                                                      "DD/MM/YYYY"
                                                                    )
                                                              }
                                                              readOnly={true}
                                                              dateFormat="DD/MM/YYYY"
                                                              onClick={() =>
                                                                handleClickOpenDatePicker(
                                                                  lists[1]
                                                                    .subKey,
                                                                  lists[1].docs[
                                                                    index1
                                                                  ],
                                                                  moment(
                                                                    items?.expire_date
                                                                  ).format(
                                                                    "YYYY-MM-DD"
                                                                  )
                                                                )
                                                              }
                                                            />
                                                          </span>
                                                          <DeleteIcon
                                                            color="primary"
                                                            style={{
                                                              marginLeft:
                                                                "10px",
                                                              marginTop: "-2px",
                                                            }}
                                                            onClick={(e) =>
                                                              deleteDocumentData(
                                                                items?.id
                                                              )
                                                            }
                                                          />
                                                        </Grid>
                                                      </Grid>
                                                    </div>
                                                  ) : (
                                                    <div className="compliance-container">
                                                      <span
                                                        style={{
                                                          paddingLeft: 35,
                                                          opacity: "0.6",
                                                        }}
                                                      >
                                                        Sorry, document does't
                                                        exist
                                                      </span>
                                                    </div>
                                                  )}
                                                </Grid>
                                              )
                                            )}
                                        </div>

                                        {lists[1]?.docs.length === 0 && (
                                          <Grid
                                            item
                                            xs={12}
                                            className={classes.boxContainer}
                                          >
                                            <div className="compliance-container">
                                              <span
                                                style={{
                                                  paddingLeft: 35,
                                                  opacity: "0.6",
                                                }}
                                              >
                                                Sorry, document does't exist
                                              </span>
                                            </div>
                                          </Grid>
                                        )}
                                      </Grid>
                                    </Paper>
                                  </Grid>
                                );
                              }
                            )}
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  </>
                );
              })}
          </Box>
          {/* <Box onSubmit={(e) => onSubmit(e)}>
            <UploadFile
              title="Copy of Passport in Colour including front cover. (Right to work)"
              key1="passport"
              handleClick={(e) => handleClick(e, "passport")}
              uploadPercentage={uploadPercentage}
              selectedFile={selectedFile}
              documentDetail={documentDetail}
            />
            <UploadFile
              title="Immunisation records - Proof of immunity for (Varicella, Tuberculosis, Rubella, Measles, Hep B Level 100). Blood results needs to be traceable to exact Clinic/ source. For EPP clearance ( HIV 1 & 2) Hep C and Hep B surface antigen ( IVS)"
              key1="immunisation_records"
              handleClick={(e) => handleClick(e, "immunisation_records")}
              uploadPercentage={uploadPercentage}
              selectedFile={selectedFile}
              documentDetail={documentDetail}
            />
            <UploadFile
              title="Mandatory training certificates- Fire safety, BLS,MH, Infection control, safeguarding child/Adult etc"
              key1="training_certificates"
              handleClick={(e) => handleClick(e, "training_certificates")}
              uploadPercentage={uploadPercentage}
              selectedFile={selectedFile}
              documentDetail={documentDetail}
            />
            <UploadFile
              title="Nursing Certificates/ Diploma/NVQ"
              key1="nursing_certificates"
              handleClick={(e) => handleClick(e, "nursing_certificates")}
              uploadPercentage={uploadPercentage}
              selectedFile={selectedFile}
              documentDetail={documentDetail}
            />
            <UploadFile
              title="Proof of Current Professional Indemnity Insurance"
              key1="professional_indemnity_insurance"
              handleClick={(e) =>
                handleClick(e, "professional_indemnity_insurance")
              }
              uploadPercentage={uploadPercentage}
              selectedFile={selectedFile}
              documentDetail={documentDetail}
            />
            <UploadFile
              title="NMC statement of entry"
              key1="nmc_statement"
              handleClick={(e) => handleClick(e, "nmc_statement")}
              uploadPercentage={uploadPercentage}
              selectedFile={selectedFile}
              documentDetail={documentDetail}
            />
            <UploadFile
              title="DBS disclosure certificate- Front and back"
              key1="dbs_disclosure_certificate"
              handleClick={(e) => handleClick(e, "dbs_disclosure_certificate")}
              uploadPercentage={uploadPercentage}
              selectedFile={selectedFile}
              documentDetail={documentDetail}
            />
            <UploadFile
              title="CV- Work history from school leaving age with no gaps. Please ensure that all dates are in (DD/MM/YY) format"
              key1="cv"
              handleClick={(e) => handleClick(e, "cv")}
              uploadPercentage={uploadPercentage}
              selectedFile={selectedFile}
              documentDetail={documentDetail}
            />
            <UploadFile
              title="TWO references covering the last 3 years of employment (must include hospital/company stamp or company/hospital logo letter head)"
              key1="employment"
              handleClick={(e) => handleClick(e, "employment")}
              uploadPercentage={uploadPercentage}
              selectedFile={selectedFile}
              documentDetail={documentDetail}
            />
            <UploadFile
              title="TWO proofs of address dated within last 3 months (bank statement, utility bill, official government letter etc."
              key1="address_proof"
              handleClick={(e) => handleClick(e, "address_proof")}
              uploadPercentage={uploadPercentage}
              selectedFile={selectedFile}
              documentDetail={documentDetail}
            />
            <UploadFile
              title="X1 passport Photo for ID badge"
              key1="passport_photo"
              handleClick={(e) => handleClick(e, "passport_photo")}
              uploadPercentage={uploadPercentage}
              selectedFile={selectedFile}
              documentDetail={documentDetail}
            />
            <UploadFile
              title="Proof of NI- Any letter from HMRC showing NI number or Copy of NI card ( front & back Copy ) -We don’t accept payslips"
              key1="proof_of_ni"
              handleClick={(e) => handleClick(e, "proof_of_ni")}
              uploadPercentage={uploadPercentage}
              selectedFile={selectedFile}
              documentDetail={documentDetail}
            />
            <UploadFile
              title="Continuous Professional Development"
              key1="continuous_professional_development"
              handleClick={(e) =>
                handleClick(e, "continuous_professional_development")
              }
              uploadPercentage={uploadPercentage}
              selectedFile={selectedFile}
              documentDetail={documentDetail}
            />
            <UploadFile
                            title="Fit to Work Certificate"
                            key1="fit_work_certificate"
                            handleClick={(e) => handleClick(e, 'fit_work_certificate')}
                            uploadPercentage={uploadPercentage}
                            selectedFile={selectedFile}
                            documentDetail={documentDetail}
                        />
            <Button variant="contained" className={classes.btnSecondary} type="submit" formNoValidate>Upload</Button>
          </Box> */}
          <CreateDatePickerEvent
            open={openDatePicker}
            handleClose={handleCloseDatePicker}
            signee_id={user_id}
            expire_date={exDateDocument}
            setExpireDate={expireDate}
          />
          <AlertDialog
            id={docId}
            open={deleteDocOpen}
            close={deleteDocClose}
            response={deleteDocResponse}
            title="Delete Candidate Document"
            description="Are you sure you want to delete?"
            buttonName="Delete"
          />
        </Container>
      </section>
    </>
  );
};

export default ComplianceList;
